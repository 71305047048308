a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}
body {
  font-family: $font-primary;
  font-size: $font-base;
  line-height: 24px;
  overflow-x: hidden;
}
.megaContent {
  padding: 1.938rem 0.938rem;

  @media (min-width: 2236px) { 
    padding: 2rem 20rem;
  }
}
.container {
  //max-width: 90%;

  @media (min-width: 2236px) { 
    max-width: 90%;
  }
}

h2 {
  font-weight: $font-w-semibold;
  font-size: $font-lg;
  font-family: $font-primary-condensed;
  line-height: $font-line-lg;
  text-transform: uppercase;
  margin-bottom: 40px;
  &.title-box {
    color: $second-color;
  }
}

.f-100 {
  font-weight: 100;
}

.f-200 {
  font-weight: 200;
}

.f-300 {
  font-weight: 300;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}

.f-700 {
  font-weight: 700;
}

.f-800 {
  font-weight: 800;
}

.f-900 {
  font-weight: 900;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.gap-4 {
  gap: 4rem;
}

.gap-5 {
  gap: 5rem;
}

.info-edit {
  font-size: $font-small;
  color: $primary-light;
  @extend .text-right;
}

svg {
  &.second-color {
    .symbol,
    .ico {
      fill: $second-color;
    }
  }
  &.light-color {
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
  &.ico-small {
    &.edit {
      width: 16.62px;
      height: 15.94px;
    }
    &.cancel {
      width: 20.78px;
      height: 20px;
    }
    &.plus {
      width: 20px;
      height: 20px;
    }
    &.status {
      width: 20.78px;
      height: 20px;
    }
  }
  &.ico-box-blue {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $first-color;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
  &.ico-box-green {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $third-color;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
  &.ico-box-light-blue {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $second-color;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
  }
}

.btn {
  border-radius: 5;
  text-transform: uppercase;
  font-weight: $font-w-bold;
  &.btn-info {
    font-size: $font-big;
  }
  @include media-breakpoint-up(sm) {
    svg {
      margin-right: 1.3rem;
    }
  }
  &.btn-link {
    padding: 0px;
    color: $second-color;
    svg {
      .symbol,
      .ico {
        fill: $second-color;
      }
    }
  }
  &.btn-primary {
    background-color: $second-color;
    border-color: $second-color;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $second-color;
      border-color: $second-color;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(8, 162, 170, 0.5);
    }
  }
  &.btn-secondary {
    background-color: $third-color;
    border-color: $third-color;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $third-color;
      border-color: $third-color;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 201, 35, 0.5);
    }
    svg {
      margin-right: 0.8rem;
    }
  }
  &.btn-success {
    background-color: $first-color;
    border-color: $first-color;
    &:focus,
    &.focus,
    &:hover,
    &.active,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $first-color;
      border-color: $first-color;
    }
    &:focus,
    &.focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem $first-color-darken;
    }
    svg {
      margin-right: 0.8rem;
    }
  }
}
.card-deck .card {
  margin-bottom: 20px;
}
.card,
.simple-box {
  margin: 20px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  @include media-breakpoint-down(sm) {
    padding: 18px 20px;

    .risk-height-row {
      padding: 18px 20px;
    }
  }
  @include media-breakpoint-up(sm) {
    padding: 18px 31.7px;

    .risk-height-row {
      padding: 18px 31.7px;
    }
  }
  .card-body {
    padding: 0;

    @media (min-width: 2236px) { 
      padding: 0 2rem;
    }
  }
  .logo-card,
  .logo-box {
    margin-top: -2.8rem;
    float: right;
    @include media-breakpoint-down(sm) {
      margin-right: -15px;
    }
  }
  .card-title,
  .title-simple-box {
    margin-bottom: 0;
  }
  .margin-title-box {
    margin-bottom: 1.87rem;
  }
  .h5,
  h5 {
    font-weight: $font-w-medium;
    font-family: $font-primary-condensed;
    @include media-breakpoint-down(sm) {
      font-size: $font-big;
      line-height: $font-line-big;
    }
    @include media-breakpoint-up(sm) {
      font-size: $font-medium;
      line-height: $font-line-medium;
    }
  }
  .list-group {
    padding-top: 28px;
    .list-group-item {
      padding: 0.68rem 0;
      span,
      strong {
        width: 50%;
      }
    }
  }
  h4 {
    font-weight: $font-w-regular;
    @include media-breakpoint-down(sm) {
      font-size: $font-lg;
      line-height: $font-line-lg-3;
    }
    @include media-breakpoint-up(sm) {
      font-size: $font-xxl;
      line-height: $font-line-xxl;
    }
  }
}
.simple-box {
  background-color: white;
}
.table {
  margin-top: 22px;
  th {
    border-top: 0 !important;
  }
  th {
    @include media-breakpoint-up(sm) {
      padding: 12px;
    }
  }
  th {
    @include media-breakpoint-up(sm) {
      padding: 0 10px 20px 10px;
    }
  }
  .ico-small {
    margin: 0 7.79px;
  }
}
.search-table {
  border: 1px solid $primary-lighter;
  border-radius: 4px;
  margin-left: 1rem;

  svg {
    margin-right: 0.5rem;
  }

  input {
    border: 0;
    padding: 0 0 0 1rem;
    margin: 0;

    &.form-control:focus,
    &.form-control:active {
      border-color: transparent;
      box-shadow: none;
    }
  }

  @include media-breakpoint-between(md, lg) {
    &.input-group {
      width: 50%;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 3px;
    border: 0;
    input {
      padding-left: 2px;
    }
  }
}

.main-container {
  flex:1;
  justify-content: center;
  //align-items: center;

  @extend .d-flex;
  .desktop-menu {
    @extend .d-none;
    @extend .d-lg-block;
  }
  .content-page {
    background: #e5e5e5;
    @extend .flex-grow-1;
  }
}

.pagination {
  .page-link {
    border: 0;
    font-size: $font-base;
    line-height: $line-height-base;
    color: $primary;
  }
  .active {
    .page-link {
      background: none;
      font-weight: $font-w-bold;
      color: $primary;
    }
  }
}
.btn-toolbar {
  margin: 1.6rem 0 1.8rem;
  .btn-link {
    margin-right: 5px;
    font-size: $font-base;
    color: $primary-light;
    text-transform: uppercase;
    padding: 5px;
    svg {
      margin-right: 0;
      margin-left: 13px;
      height: 32px;
      width: 27.67px;
      .symbol,
      .ico {
        fill: $light-primary;
      }
      .box,
      .esagono {
        fill: $primary-light;
      }
    }
    &.active {
      background-color: $third-color;
      color: $first-color;
      font-weight: $font-w-bold;
      .box,
      .esagono {
        fill: $first-color;
      }
      svg {
        width: 43.24px;
        height: 50px;
        &.tooltip-ico {
          path {
            fill: $light-primary;
          }
        }
      }
    }
  }
}

.tab-box {
  .nav-tabs {
    text-transform: uppercase;
    @extend .d-flex;
    @extend .justify-content-center;
    margin-bottom: 57px;
    @include media-breakpoint-down(md) {
      margin-bottom: 37px;
    }
    a {
      color: $primary-light;
      font-weight: $font-w-semibold;
      &.active {
        color: $second-color;
        border: 0;
        margin-bottom: -1px;
        border-bottom: 3px solid $second-color;
        &:hover,
        &:focus {
          border-bottom: 3px solid $second-color;
        }
      }
      &:hover,
      &:focus {
        border-color: #fff #fff #e9ecef #fff;
        color: $second-color;
      }
    }
    p,
    button {
      margin-bottom: 18px;
    }
  }
  .tab-content {
    ul {
      padding-left: 18px;
      li {
        padding: 9px 0;
      }
    }
  }
}

.light-box-link {
  position: relative;
  display: inline-block;
  .ico-absolute {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

svg {
  &.tooltip-ico {
    margin-left: 0.3rem;
    vertical-align: baseline;
  }
}

.area-card {
  .card-container {
    margin: 40px 0 100px;
  }
  &.simple-card {
    .card-container {
      margin: 10px 0;
    }
  }
  .card,
  .simple-box {
    margin: 20px 0;
    border: 0;
    background-color: $primary-lightest-1;
    @extend .h-100;
    box-shadow: none;
    @include media-breakpoint-down(sm) {
      padding: 18px 20px;
    }
    @include media-breakpoint-up(sm) {
      padding: 18px 31.7px;
    }
    .card-body {
      padding: 0;

      @media (min-width: 2236px) { 
        padding: 0 3rem;
      }
    }
    .logo-card,
    .logo-box {
      margin-top: -2.8rem;
      float: right;
      @include media-breakpoint-down(sm) {
        margin-right: -15px;
      }
    }
    .card-title,
    .title-simple-box {
      margin-bottom: 20px;
    }
    .margin-title-box {
      margin-bottom: 1.87rem;
    }
    .h5,
    h5 {
      font-size: $font-medium;
      font-weight: $font-w-bold;
      color: $second-color;
      text-transform: uppercase;
    }
    .list-group {
      padding-top: 28px;
      .list-group-item {
        padding: 0.68rem 0;
        span,
        strong {
          width: 50%;
        }
      }
    }
  }
}

svg {
  max-width: 100%;
  &.ico-box-base {
    width: 44px;
    height: 50px;
    .box,
    .esagono {
      fill: $third-color;
    }
    .symbol,
    .ico {
      fill: $light-primary;
    }
    .ico-text {
      font-size: $font-small;
      font-weight: $font-w-bold;
      line-height: 17px;
      fill: $primary;
      text-transform: uppercase;
    }
  }
}

.tab-content {
  .number-row {
    padding-top: 70px;
    padding-bottom: 70px;
    font-size: $font-medium;
    @include media-breakpoint-down(md) {
      padding-bottom: 37px;
      padding-top: 37px;
    }
    strong {
      font-size: $font-lg;
      font-weight: $font-w-bold;
      color: $second-color;
      display: block;
    }
    .number {
      font-size: $font-xlg-2;
      line-height: $font-xlg-2;
      font-weight: $font-w-bold;
      color: $second-color;
      padding-right: 0;
      @include media-breakpoint-down(md) {
        font-size: $font-lg-3;
        line-height: $font-lg-3;
      }
    }
    .col-md-4 {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
  }
}

.bb {
  margin-top: 2em;
}

p.va-desc {
  color: $primary-light;
  font-size: $font-small;
  margin-top: 5px;
}

.span-legend {
  width: 100%;
  line-height: 2.5em;
  display: inline-block;
}

.value-label {
  padding: 1em 0.6em !important;

  span[data-trans="A0191"] {
    @extend .d-flex;
  }
}

.form-group {
  margin-bottom: 2rem;
}

input {
  font-size: 1.1rem;
}

.global-toast {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9999;
  min-width: 20rem;
  height: auto !important;
  color: initial !important;
  padding: 0 !important;
  font-size: 1rem !important;
  background-color: white !important;

  /* .toast-header {
    button.close {
     
    }
  } */
}

.form-control:disabled, .form-control[readonly] {
  background-color: hsl(0,0%,95%);
  border-color: hsl(0,0%,90%);
  color: hsl(0,0%,60%);
}

.no-to-head {
   white-space: nowrap;
}

.w-max-content {
   width: max-content;
}

.h-90 {
   height: 90%;
}

.h-95 {
   height: 95%;
}