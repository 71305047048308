span.p-component {
   width: 100%;

   input {
      padding: 8px 16px;
      text-align: right;
   }

   &.sm {
      height: 1.938rem;

      input {
         font-size: 0.875rem !important;
      }
   }
   
   &.md {
      height: 2.375rem;

      input {
         font-size: 1rem !important;
      }
   }

   &.lg {
      height: 3rem;

      input {
         font-size: 1.25rem !important;
      }
   }

   &.t-center {
      input {
         text-align: center !important;
      }
   }

   &.t-left {
      input {
         text-align: left !important;
      }
   }

   input.p-disabled {
      opacity: 1;
      background-color: #F2F2F2;
      border-color: #E6E6E6;
      color: #999999 !important;
   }
}