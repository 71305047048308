#valutazione {
  background: $primary-lightest;
  line-height: 34.5px;

  .btn {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .btn-nav {
    background-color: $third-color;
    border-color: $third-color;
    color: #ffffff;
  }

  .btn-nav .active {
    background-color: $first-color;
    border-color: $first-color;
    color: #ffffff;
  }

  .nav {
    color: $primary-lighter;
    .active {
      color: $first-color;
    }
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    table-layout: fixed;
    width: 100%;
  }
  table tbody {
    display: table;
    width: 100%;
  }

  table thead {
    display: table;
    width: 100%;
  }

  a {
    color: $primary-light;
    &:hover,
    &:visited {
      color: $primary-light;
    }
  }

  .main-wrapper {
    width: 100%; //width: 70vw;

    overflow-x: auto;

    box-sizing: border-box;
    display: table;
    table-layout: fixed;
    margin-bottom: 2rem;
  }

  .impact {
    .chartBox {
      //padding: 1em 3em;
      text-align: center;
      button {
        background-color: $first-color;
        border-color: $first-color;
      }
      h5 {
        text-align: center;
        // margin-top: .5em;
      }
      &.impactChart {
        //min-width: 210px;
        //width: 16.65%;
        min-width: 11rem;
        width: 20%;
        max-width: 180px;
        h5 {
          text-transform: uppercase;
          &.impact-total {
            font-size: $font-line-lg;
            line-height: $font-line-lg;
            font-weight: $font-w-semibold;
            margin-top: 1em;
          }
        }
        a {
          color: #000;
          &:hover,
          &:visited {
            color: #000;
          }
        }
        .va-desc {
          @extend .text-center;
          font-size: $font-small;
        }
      }
    }
  }

  .container-card {
    //@extend .d-flex;
    .card,
    .simple-box {
      flex: 1;
      div.tooltipInfoBox {
        @include media-breakpoint-up(sm) {
          width: 12vw;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  //GLF Fix for adapting SVG images
  .img-card {
    width: 100%;
  }
  
  #submitted-button {
    background-color: inherit;
    color: inherit;
    opacity: 1;
    width: fit-content;
  }
}

#statusbar {
  .simple-box {
    color: #ffffff;
    font-weight: bold;
  }
  .success {
    background-color: #c2c923;
  }

  .error {
    background-color: #cb1b4a;
  }

  .elaborating {
    background-color: #08a2aa;
  }
}

#template-upload-form {
  .form-group {
    margin-bottom: 0px;
  }
}

.hr-subcategory {
  border-top: 1px solid;
}

.hr-question {
  border-top: 1px dotted;
}

.assessment-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: 11rem;
  font-size: 1.2rem;
}

.add-aud-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  width: fit-content;
  font-size: 1.2rem;
}

.sticky-footer-button {
  position: sticky;
  bottom: 1.3rem;
  z-index: 1000;
  animation: fadeInTEST 0.3s;
  transition: opacity 0.4s;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
}

.accordion-arrow {
  width: 25px;
  height: 25px;
}

.accordion {
  display: flex;
  flex-direction: column-reverse;
  //cursor: pointer;
}

.accordion .accordion-title {
  cursor: pointer;

  /* .tooltip-inner {
    max-width: 300px !important;
  } */
}

.tooltip-maxWith .tooltip-inner {
  max-width: 300px !important;
}

h3 .accordion-arrow, h5 .accordion-arrow {
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

div[class~="show"] + h3 .accordion-arrow, div[class~="show"] + h5 .accordion-arrow {
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

//scrollbar
/* width */
::-webkit-scrollbar {
  width: 12px;
}

table::-webkit-scrollbar {
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 1px;
}

table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1c2743;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1c2743;
}

.addon-for-number {
  background-color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .collapse {
  //pointer-events: none;
  cursor: inherit !important;
}

.form-group#select-sroi div {
  justify-content: center;
}

.addon-for-number-sroi.input-group-text {
  background-color: white;
  border: 0 !important;
}

.question-code {
  width: fit-content;
  -moz-border-radius: 5%;
  border-radius: 5%;
  border: solid 2px $third-color;
  color: $first-color;
  text-align: center;
  display: block;
  font-size: small;
  font-weight: 400;
  padding: 0 0.3rem;
}

.hide {
  display: none;
}

.fade-in-div {
  animation: fadeIn linear 500ms;
  -webkit-animation: fadeIn linear 500ms;
  -moz-animation: fadeIn linear 500ms;
  -o-animation: fadeIn linear 500ms;
  -ms-animation: fadeIn linear 500ms;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.fade-out-div {
  animation: fadeOut linear 500ms;
  -webkit-animation: fadeOut linear 500ms;
  -moz-animation: fadeOut linear 500ms;
  -o-animation: fadeOut linear 500ms;
  -ms-animation: fadeOut linear 500ms;
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

.scrollTop {
  position: fixed; 
  width: auto;
  bottom: 1.30rem;
  //transform: translate(-50%, -50%);
  right: 5rem;
  align-items: center;
  height: 1.25rem;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeInTEST 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeInTEST {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.hide-number-arrows {
  input[type=number]::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
  }
}

table.sroi-table {
  width: 100%;

  th,
  td {
    padding: 0.8em 0.6em;
  }
  td {
    border-bottom: 1px solid #dbe4ec;
  }
  tr.total {
    font-weight: bold;

    td {
      border-bottom: none;
    }
  }
  .value-label {
    div.tooltipInfoBox {
      transform: translate(0px, 0px) !important;
    }
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    background-color: #1c2743;
    color: #fff;
  }
  
  tbody tr th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    background-color: #fff;
  }

  thead th:first-child {
    left: 0;
    z-index: 10;
  }
  
  tbody tr th:first-child {
    left: 0;
    z-index: 10;
    min-width: 25rem;
    width: 25rem;
    border-bottom: 1px solid #dbe4ec !important;
  } 
}

.th-width {
  width: 25rem;
  min-width: 25rem;
}

.cell-width {
  width: 17rem;
  min-width: 17rem;

  @media (min-width: 2236px) {
    width: 22rem;
    min-width: 22rem;
  }
}

.total-width {
  min-width: 17rem;

  @media (min-width: 2236px) {
    min-width: 22rem;
  }
}

input[type="radio"], input[type="checkbox"] {
  margin-top: 0.563rem;
  //vertical-align: middle;
  //height: 100%;
}

#technical-people-div {
  margin-top: 8rem;
  margin-bottom: 2rem;

  .row {
    span.description {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 2rem;
      border-left: 13px solid #1c2743;
    }

    .form-label {
      width: max-content;
    }

    .form-group {
      max-width: max-content;
    }

    .form-group input{
      min-width: 17rem;
      max-width: 17rem;

      @media (min-width: 1600px) {
        min-width: 18rem;
        max-width: 18rem;
      }

      @media (min-width: 1920px) {
        min-width: inherit;
        max-width: 25rem;
      }
    }

    @media (min-width: 2236px) {
      grid-gap: 10rem;
      gap: 10rem;

      .form-label {
        width: inherit;
      }
  
      .form-group {
        max-width: inherit;
      }
    }
  }
  
}

#simple-sroi-div {
  margin-top: 7rem;

  .row {
    span.simple-sroi-span {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 2rem;
      border-left: 13px solid #1c2743;
    }

    
  }
  
}

.residual-life {
  background-color: #c2c92342;

  &:focus {
    background-color: #c2c92342;
  }
}

.simple-box.instruction {
  width: fit-content;
  margin: 0 auto;
  margin-top: 2.3rem;

  &.sroi {
    margin-bottom: 2.5rem;

    @media (min-width: 1920px) {
      max-width: 75%;
    }
  }
}


.fade-in-accordion.accordion .collapsing {
  transition: all 1s ease;
}

.instructionSection {
   column-count: 2; 
   column-gap: 4rem;
   padding: 0 1rem;
}

.percentage-max-width {
   max-width: 5rem;
   text-align: right;
}

.col-05 {
   flex: 0 0 5%;
   max-width: 5%;
}

.question-line {
   padding: 0.8em 0.6em 0.8rem 0;
   border-left: 4px solid #1c2743;

   &.list-perc-question {
      height: 20rem;
   }

   &.other-question {
      height: 5rem;
   }
}

.m-hr {
   margin: 4rem 20rem;
}

.aud-project .m-hr {
   margin: 2rem 20rem;
   background-color: $third-color;
}

.aud-project .input-width {
   margin: auto;
   width: 17rem;
   min-width: 17rem;
 
   &.larger {
      width: 25rem;
      min-width: 25rem;
   }

   &.middle-select {
      width: 17rem;
      min-width: 17rem;
   }

   @media (max-width: 1366px) {
      width: 11rem;
      min-width: 11rem;
   }

   @media (min-width: 1367px) and (max-width: 1600px) {
     width: 15rem;
     min-width: 15rem;
   }

   .center-text__value-container {
      justify-content: center;
   }
   .center-text__indicators {
      position: absolute;
      right: 0;
      height: 100%;
   }
 }