.esg-infobox {
  //max-width: inherit;

  .first-column {
    font-weight: 600;
    font-size: 1.3rem;
    white-space: nowrap;
  }

  .second-column {
    font-weight: bold;
    font-size: 1.5em;
    white-space: nowrap;
  }

  .buttongroup-esg {
    color: black !important;

    .btn-secondary {
      color: black !important;
    }

    .btn:disabled {
      opacity: 1;
    }
  }

  .fourth-column {
    .femaleaud {
      font-size: 1.5em;
      font-weight: 600;
    }

    .descr {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
}

.border-top-bottom {
  border: 3px solid rgb(91, 91, 91) !important;
}

.button-group-base {
  border-color: white !important;
  min-width: 4.7rem;
  text-shadow: 0 0 4px #9f9f9f;
  white-space: nowrap;
}

.button-group-rating {
  border-color: white !important;
  min-width: 4.7rem;
  opacity: 0 !important;
}

.showRatingESG {
  opacity: 1 !important;
  background-color: grey !important;
}

.arrow-down {
  width: 0; 
  height: 0; 
  margin: 0 auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgb(255, 255, 255);
}

.arrow-up {
  width: 0; 
  height: 0; 
  margin: 0 auto;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgb(255, 255, 255);
}

//SROI

.sroi-analysis {
  color: white;
  text-align: center;
  font-weight: bold;
  //line-height: 100px;

  span {
    display : inline-block;
    vertical-align : middle;
  }
}

.not-applicable {
  margin: 5rem 3rem;
  font-size: 2.5rem;
  font-weight: 500;
}

#dati-generali-details {
  gap: 1rem;
  
  @media (min-width: 2236px) { 
    gap: 6rem;
  }
}

#dati-generali-details .col .row, #sroi-details .col .row {
  padding: 1rem 0rem 1rem 0.25rem;

  @media (min-width: 2236px) { 
    padding: 1rem 0.5rem;
  }
}

#totalcapex-text {
  //position: absolute;
  max-width: fit-content;
  flex-basis: min-content;

  @media (min-width: 1920px) { 
    position: inherit !important;
    max-width: inherit !important;
  }

  @media (max-width: 1367px) { 
   flex: 0 0 50%; //col-6
   max-width: 50%; //col-6
 }
}

.basis-fit-content {
  flex-basis: fit-content;
}

.svg-materiality {
  height: 4.5rem;
  width: 4.5rem;
  color: #333;
  margin-right: 3rem;

  @media (min-width: 1960px) { 
    margin-right: 3.5rem;
  }

  .span.env {
    text-decoration: underline;
    text-decoration-color: #c2c923;
    -webkit-text-decoration-color: #c2c923; /* Safari */  
  }
}

.border-materiality {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border-header-materiality {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#row-environmental, #row-social, #row-governance {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.gap-title {
  //gap: 2rem;

  @media (min-width: 2236px) { 
    gap: 4rem;
  }

  @media (min-width: 4096px) { 
    gap: 0rem;
  }
}

.padding-legend-media {
  padding-left: 0rem;

  @media (min-width: 1600px) { 
    padding-left: 1rem;
  }

  @media (min-width: 1920px) { 
    padding-left: 2rem;
  }

  @media (min-width: 4096px) { 
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

.padding-media {
  padding-left: 1rem;

  @media (min-width: 1600px) { 
    padding-left: 2rem;
  }

  @media (min-width: 1920px) { 
    padding-left: 3rem;
  }

  @media (min-width: 4096px) { 
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

.center-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#show-more {
  color: inherit !important;
  text-decoration: underline;
  margin: 1rem;
  text-transform: inherit !important;
}

.box-materiality {
  height: 1.25rem;
  width: 5rem;
  margin-right: 2rem;
}

.box-materiality.green-1 {
  background-color: #689689;
}

.box-materiality.green-2 {
  background-color: #82B58F;
}

.box-materiality.green-3 {
  background-color: #9CD394;
}

.box-materiality.grey {
  background-color: #cdcdcd;
}

.padding-table {
   @media (max-width: 1366px) { 
      padding: 0 !important;
   }
}

.bm-table {
   width: 100%;
   overflow: auto;

   thead th {
      //color: #666666;
      text-transform: uppercase !important;
      font-weight: inherit;
      //background-color: #666666;
      font-size: 1rem;
      padding: 8px 10px 8px 0;
      border-bottom: 1px solid #dee2e6 !important;
   }

   thead th:first-child {
      border: 0 !important;
   } 

   thead th:nth-child(2) {
      text-align: left !important;
      color: $first-color;
      padding-left: 14px;
      padding-right: 0rem;
   } 

   tbody, tr, td {
      padding: 12px 10px;
   }

   tbody td:first-child {
      font-weight: 500;
      border: 0 !important;
   } 

   tbody td:nth-child(2) {
      text-align: left !important;
      padding-left: 14px;
   } 
 }

.risk-section {
   justify-content: space-between;
}

.col-resident-population {
   text-align: -moz-center;
   text-align: -webkit-center;
}

.resident-population {
   border: 2px solid $primary-lighter;
   border-radius: 0.3rem;
   margin-top: 3rem;
   margin-bottom: 2rem;
   padding: 2rem;
   width: 100%;
   justify-content: center;
}

.value-isvm {
   color: white;
   padding: 10px;
   font-weight: 700;
   border-radius: 0.25rem;
   text-shadow: 0 0 4px #9f9f9f;
}

.sources {
   font-size: 1rem;

   a {
      color: $primary-light;
      &:hover,
      &:visited {
         color: $primary-light;
      }
   }
}

.risk-height-row {
   position:absolute;
   bottom:0;
   right: 0;
}

.esg-header.columns {
   font-size: 1rem;
   color: #666666;
}

.esg-header.rows {
   font-size: 1.2rem;
}