//thanks for user survey
#thanks {
    margin-top: 4rem;
    display: flex;
    justify-content: center;

    #thanks-first-border {
        border: #1c2743 4px solid;
        //border-radius: 50px 15px;
    }
    
    #thanks-second-border {
        border: #08a2aa 4px solid;
        //border-radius: 50px 15px;
    }
    
    #thanks-third-border {
        border: #c2c923 4px solid;
        //border-radius: 50px 15px;
        min-height: 25rem;
        min-width: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

//thanks for user assessment
.row-thanks {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-align: -webkit-center;
    flex-direction: column-reverse;

    @media (min-width: 992px) {
        margin-top: 4rem;
        flex-direction: row;
        width: 60%;
        margin-right: auto;
        margin-left: auto;
    }
    @media (min-width: 1600px) {
        width: 45%;
    }
    @media (min-width: 2560px) {
        margin-top: 7rem;
        width: 35%;
    }
    @media (min-width: 4096px) {
        margin-top: 15rem;
        width: 25%;
    }

    .text-thanks {
        width: fit-content;
        //text-align: center;

        @media (min-width: 2236px) {
            //margin: 0 5rem 0 auto;
        }
    }

    .img-thanks {
        max-height: 16rem;
        margin-bottom: 3rem;
    }
}


//Certificate
.parent-thanks {
   display: flex; //table
   //font-family: Georgia, serif;
   font-size: 24px;
   text-align: center;
   margin: auto;
   justify-content: center;
   padding: 1rem;
}

#container-thanks {
   border: 17px solid tan;
   border-radius: 7px;
   width: 900px;
   height: 563px;
   //display: table-cell;
   margin-left: 0.5rem;
}

.logo-thanks {
   margin-top: 2rem;

   svg {
      max-height: 4rem;
   }
}

.marquee {
   color: tan;
   font-size: 38px;
   margin: 20px;
   white-space: nowrap;
}
.assignment {
   margin: 20px;
}
/* .person {
   border-bottom: 2px solid black;
   font-size: 32px;
   font-style: italic;
   margin: 20px auto;
   width: 400px;
} */
.reason {
   margin: 20px;
}